import { makeAutoObservable } from "mobx";
// import { ResponseMode } from "../enums/qa";
import { FileMode, FileStatus } from "../enums/file";
import { ModelOption } from "../enums/prompt";
import { authStore } from ".";

class SessionStore {
  // Fetch files from server
  personal_files = [];
  
  // Current selected model
  model_type = ModelOption.AzureOpenAI_GPT_3_5

  // Current file selected
  selectedPersonalFiles = []
  selectedGroupFiles = {}
  selectedFolders = {}
  selectedSubFolders = {}
  selectedAll = {}

  constructor() {
    makeAutoObservable(this);
  }

  get req_file_ids() {
    return {
      "selectedPersonalFiles": this.selectedPersonalFiles,
      "selectedGroupFiles": this.selectedGroupFiles
    } 
  }

  set_req_file_ids(reqfid){
    this.selectedPersonalFiles = reqfid.selectedPersonalFiles
    this.selectedGroupFiles = reqfid.selectedGroupFiles
  }

  get selectedPersonalFileIds () {
    return this.selectedPersonalFiles.map(x => x._id)
  }

  get selectedGroupIdsToFileIds () {
    // Given selectedGroupFiles is {'{group_id}_{file_id}`},
    // Since selectedGroupFiles is dict, first filter out key that value is false
    // Convert to mapping object where key is group_id and value is file_id list
    let groupToFileIds = {};
    for (let key in this.selectedGroupFiles) {
      if (this.selectedGroupFiles[key]) {
        let [groupId, fileId] = key.split('_');
        if (!groupToFileIds[groupId]) {
          groupToFileIds[groupId] = [];
        }
        groupToFileIds[groupId].push(fileId);
      }
    }
    return groupToFileIds;
  }


  get selectedGroupIdsToFileCounts () {
    // Given selectedGroupFiles is {'{group_id}_{file_id}`},
    // Since selectedGroupFiles is dict, first filter out key that value is false
    // Convert to mapping object where key is group_id and value is count of file_ids
    let groupToFileCounts = {};
    let groupCount = {};
    for (let key in this.selectedGroupFiles) {
      if (this.selectedGroupFiles[key]) {
        let [groupId, fileId] = key.split('_');
        if (!groupCount[groupId]) {
          groupCount[groupId] = 0;
        }
        groupCount[groupId]++;
      }
    }
    for (let groupId in groupCount) {
      groupToFileCounts[groupId] = groupCount[groupId];
    }
    return groupToFileCounts;
  }
  
  get someFileSelected() {
    return this.selectedPersonalFileIds.length > 0 || Object.keys(this.selectedGroupIdsToFileIds).length > 0
  }

  get selectedFileDisplay(){
    console.log(this.selectedGroupIdsToFileCounts)
    let personalFileCount = this.selectedPersonalFileIds.length;
    let groupFileCount = Object.keys(this.selectedGroupIdsToFileIds).length;
    let displayMessages = [];
    if (personalFileCount > 0) {
      let fileText = personalFileCount > 1 ? 'files' : 'file';
      displayMessages.push({title: `personal (${personalFileCount} ${fileText})`, type: 'personal', groupId: ''});
    }
    if (groupFileCount > 0) {
      Object.keys(this.selectedGroupIdsToFileCounts).forEach(groupId => {
        let groupName = authStore.access_group_list.find(group => group.id === groupId).name;
        let fileText = this.selectedGroupIdsToFileCounts[groupId] > 1 ? 'files' : 'file';
        displayMessages.push({title: `${groupName} (${this.selectedGroupIdsToFileCounts[groupId]} ${fileText})`, type: 'group', groupId: groupId});
      });
    }
    if(displayMessages.length === 0){
      return [{title: "No file selected", type: '', groupId: ''}]
    }
    return displayMessages;
  }

  get compute_file_mode() {
    let use_file_mode = FileMode.None
      const group_list = Object.keys(this.selectedGroupIdsToFileIds)
      if (group_list.length > 1){
          use_file_mode = FileMode.MIX
      } else if (group_list.length == 1){
          use_file_mode = FileMode.Group
      } else {
          // group file did not use
          if (this.selectedPersonalFileIds.length > 0){
              use_file_mode = FileMode.Personal
          }
          // Other wise it will be not set as default is None
      }

      return use_file_mode
  }
  
  setModelType(model_type){
      this.model_type = model_type
  }

  setDefaultFile() {
  }

  unSelectFileV2(type, groupId) {
    if (type === 'personal') {
      this.selectedPersonalFiles = []
    }

    if (type === 'group') {
      for (const key in this.selectedGroupFiles) {
        if (Object.hasOwnProperty.call(this.selectedGroupFiles, key) && key.includes(groupId)) {
          delete this.selectedGroupFiles[key];
        }
      }
      for (const key in this.selectedFolders) {
        if (Object.hasOwnProperty.call(this.selectedFolders, key) && key.includes(groupId)) {
          delete this.selectedFolders[key];
        }
      }
      for (const key in this.selectedSubFolders) {
        if (Object.hasOwnProperty.call(this.selectedSubFolders, key) && key.includes(groupId)) {
          delete this.selectedSubFolders[key];
        }
      }
      for (const key in this.selectedAll) {
        if (Object.hasOwnProperty.call(this.selectedAll, key) && key.includes(groupId)) {
          delete this.selectedAll[key];
        }
      }
    }
  }

  unSelectFile() {
      this.selectedPersonalFiles = []
      this.selectedGroupFiles = {}
      this.selectedFolders = {}
      this.selectedSubFolders = {}
      this.selectedAll = {}
  }

  get isFileProcess() {
    return this.personal_files.some((item) => {
      return (
        item["status"] != FileStatus.Done && item["status"] != FileStatus.Failed
      );
    });
  }

  // TODO: Make it useable again
  setFileFromMemory(session){
    if(session && session.last_req_file_ids_v2){
      this.set_req_file_ids(session.last_req_file_ids_v2)
    } else {
      this.unSelectFile()
    }
  }

  reset() {
    this.personal_files = [];
    this.setDefaultFile()
    this.unSelectFile()
  }
}

export default SessionStore;
